<template>
    <div v-if="dataSource" class="p-relative">

        <Toolbar                         
            v-on:toolbar-button-click="onToolbarClick"  
        > 
            <!--Панель поиска-->
            <SearchPanel
                v-model="search"
                :has-filter="true"
                @on-filter-click="onFilterClick"
            />
        </Toolbar>
       
        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="collection"
            v-bind:substatus.sync="status"
        >
            <ExtendedFilterPanel 
                v-if="$helpers.isExtendedFilterPanelVisible(extendedTableFilter)"
                v-bind:value.sync="extendedTableFilter"
            />
        </FilterPanel>
        
        <component
            :is="collection"
            :status="status"
            :search="search"
            ref="currentComponent"
        />

        <IncomingRegisteredExFilterDlg
            v-if="collection == 'IncomingRegistered'"
            v-model="extendedTableFilter" 
            ref="IncomingRegisteredExFilterDlgRef" 
        />

        <IncomingUnregisteredExFilterDlg
            v-if="collection == 'IncomingUnregistered'"
            v-model="extendedTableFilter" 
            ref="IncomingUnregisteredExFilterDlgRef" 
        />

    </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import { httpAPI } from "@/api/httpAPI";

import Toolbar from '@/components/documents/Toolbar.vue';
import SearchPanel from '@/components/documents/SearchPanel.vue';
import FilterPanel from '@/components/documents/FilterPanel.vue';
import ExtendedFilterPanel from '@/components/documents/ExtendedFilterPanel.vue';

import IncomingRegisteredExFilterDlg from '@/components/documents/dialogs/extended-filters/Incomings.vue';
import IncomingUnregisteredExFilterDlg from '@/components/documents/dialogs/extended-filters/chancellery/incoming.vue';

import IncomingRegistered from './Registered.vue';
import IncomingUnregistered from './Unregistered.vue';

export default {
    name: 'Incomings',
    components: {
        FilterPanel,
        IncomingRegisteredExFilterDlg,
        IncomingUnregisteredExFilterDlg,
        SearchPanel,
        ExtendedFilterPanel,
        Toolbar,
        IncomingRegistered,
        IncomingUnregistered
    },
    data () {
        return {
            dataSource: null
        }
    },
    computed: {
        collection: {
            get: function() {
                return this.$store.getters['edslight/incomings/getCollection'];
            },
            set: function(newValue) {
                this.$store.commit('edslight/incomings/SET_COLLECTION', newValue);
            }
        },
        status: {
            get: function() {
                return this.$store.getters['edslight/incomings/getStatus'];
            },
            set: function(newValue) {
                if (typeof this.$refs.currentComponent?.resetPage === "function") 
                    this.$refs.currentComponent.resetPage();

                if (newValue)
                    this.$store.commit('edslight/incomings/PUSH_STATUS', { collection: this.collection, value: newValue });
            }
        },
        search: {
            get: function() {
                return this.$store.getters['edslight/incomings/getSearch'];
            },
            set: _.debounce(function(v) {
                if (typeof this.$refs.currentComponent?.resetPage === "function")
                    this.$refs.currentComponent.resetPage();

                this.$store.commit('edslight/incomings/PUSH_SEARCH', { collection: this.collection, value: v } );
            }, 500)
        },
        filterDataSource() {
            return this.$store.getters['edslight/incomings/getFilter'];
        },
        extendedTableFilter: {
            get: function() {
                return this.$store.getters['edslight/incomings/getExtendedFilter'];
            },
            set: function(newValue) {
                if (typeof this.$refs.currentComponent?.resetPage === "function")
                    this.$refs.currentComponent.resetPage();
                
                if (newValue)
                    this.$store.commit('edslight/incomings/PUSH_EXTENDED_FILTER', { collection: this.collection, value: newValue });
            }
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        ...mapActions('edslight/incomings', ['prepareDefaults', 'updateFilterCounters']),
        onToolbarClick(event, btn) {
            let method = this[btn.Action];

            if (method)
                method(btn);
            else
                this.$notify.alert(`Действие ${btn.Action} не реализовано`);
        },
        async loadButtons() {
            this.setOverlayVisible({ visible: true });
            this.$eventBus.$emit('update_edslight_counters');
            let type = '';

            switch (this.collection)
            {
                case 'IncomingUnregistered':
                    type = 'Chancellery.Document'; break;

                default:
                    type = 'Documents.Correspondence'; break;
            }

            let response = await httpAPI({
                url: `api/actions/collection?type=${type}`,
                method: 'GET'
            });

            if (response) {
                await this.loadFilters();
                this.dataSource = response.data.Payload;
                this.$store.commit('documents/toolbar/SET_MENU', {menu: this.dataSource.Data.Menu });
            }
            
            this.setOverlayVisible({ visible: false });
        },
        async loadFilters() {
            let response = await httpAPI({
                url: `api/edslight/incomingcounters?collection=${this.collection}`,
                method: 'GET'
            });

            this.updateFilterCounters(response?.data?.Payload);
        },
        async onFilterClick() {
            try
            {
                switch(this.collection)
                {
                    case "IncomingRegistered": await this.$refs.IncomingRegisteredExFilterDlgRef.open(); break;
                    case "IncomingUnregistered": await this.$refs.IncomingUnregisteredExFilterDlgRef.open(); break;
                    default: break;
                }
            }
            catch (ex)
            {
                if (ex.isCancelled) 
                    console.warn(ex.message);
                else
                    console.error(ex.message);
            }
        },
        async Refresh() {
            await this.loadFilters();
            this.$refs.currentComponent.getData(true);
        },
        async Export() {
            let filter = this.$refs.currentComponent.getTableFilterObject();
            delete filter.Limit;
            delete filter.Offset;
            let exportURI = `api/download/export?param=${JSON.stringify(filter)}`;
            this.$eventBus.$emit('on-get-report', {url: exportURI, text: `Экспорт списка входящих документов от ${this.$moment().format('DD.MM.YYYY HH.mm.ss')}`, type: 'Xls' });
        }
    },
    watch: {
        collection: function(newValue, oldValue) {
            if (oldValue != null)
                this.loadButtons();
        }
    },
    async created() {
        await this.prepareDefaults();
        await this.loadButtons();
    }
}
</script>
